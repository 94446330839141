<template>
  <v-card :class="{ 'd-print-none': !tableAttrs.items.length }">
    <v-data-table-server v-bind="tableAttrs" fixed-header v-on="tableListeners">
      <template #top>
        <v-card-title>
          <v-row>
            <v-col cols="12" md="6">Disbursement to principal</v-col>

            <v-col cols="12" md="6" class="text-right d-print-none">
              <v-btn
                v-tooltip="'Search'"
                type="submit"
                form="debtFilters"
                size="small"
                color="blue"
                icon="mdi-magnify"
                variant="text"
                @click="fetchItems"
              />

              <v-btn
                v-tooltip="printExpand ? 'Hide columns' : 'Show columns'"
                size="small"
                :color="printExpand ? 'orange' : 'blue'"
                :icon="
                  printExpand
                    ? 'mdi-table-column-remove'
                    : 'mdi-table-column-plus-after'
                "
                variant="text"
                @click="printExpand = !printExpand"
              />

              <BaseModal
                v-if="
                  $gates.hasPermission('debt.export.calculation') &&
                  $gates.hasPermission('user.is_employee')
                "
                actions
                title="Did you want to export the data?"
                @confirm="exportExcel"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-tooltip="'Export'"
                    variant="text"
                    size="small"
                    color="green"
                    icon="mdi-file-excel"
                    v-bind="props"
                  />
                </template>
              </BaseModal>

              <v-btn
                v-tooltip="filterExpand ? 'Hide filter' : 'Show filter'"
                size="small"
                :color="filterExpand ? 'orange' : 'blue'"
                icon="mdi-filter"
                variant="text"
                @click="filterExpand = !filterExpand"
              />
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-form
              v-show="filterExpand"
              id="debtFilters"
              class="d-print-none"
              @submit.prevent="fetchItems"
            >
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field v-model="search.ref_num" label="Ref. nummer" />
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field v-model="search.dossier" label="Dossier" />
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    v-bind="debtorAttrs"
                    label="Debiteur"
                    clearable
                    v-on="debtorListeners"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    v-bind="creditorAttrs"
                    label="Crediteur"
                    clearable
                    v-on="creditorListeners"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="search.status"
                    :items="statusOptions"
                    label="Status"
                    clearable
                    chips
                    multiple
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="search.rental"
                    :items="debtTypesOptions"
                    label="Type"
                    clearable
                  />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                  <div class="text-center">Period</div>

                  <div class="d-flex no-wrap">
                    <v-text-field
                      v-model="search.date_min"
                      type="date"
                      class="mr-2"
                      label="Min"
                      prefix=">="
                      clearable
                    />

                    <v-text-field
                      v-model="search.date_max"
                      type="date"
                      label="Max"
                      prefix="<="
                      clearable
                    />
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-expand-transition>
        </v-card-title>
      </template>

      <template #[`item.debt.ref_num`]="{ item }">
        <a
          class="text-reset font-weight-bold text-nowrap"
          :href="route('debts.show', [item.debt.debt_id])"
        >
          {{ item.debt.ref_num }}
        </a>
      </template>

      <template #[`item.debt.links.creditor`]="{ item }">
        <div v-for="link in item.debt.links" :key="link.id">
          <template v-if="[3, 4].includes(link.responsibility)">
            <PeopleLink v-if="link.person" :person="link.person" bold />
            <DepartmentLink
              v-else-if="link.department"
              :department="link.department"
              bold
            />
          </template>
        </div>
      </template>

      <template #[`item.debt.links.debtor`]="{ item }">
        <div v-for="link in item.debt.links" :key="link.id">
          <template v-if="[1, 2].includes(link.responsibility)">
            <PeopleLink v-if="link.person" :person="link.person" bold />
            <DepartmentLink
              v-else-if="link.department"
              :department="link.department"
              bold
            />
          </template>
        </div>
      </template>

      <template #tfoot="{ columns }">
        <tfoot>
          <tr>
            <th
              v-for="header in columns"
              :key="header.key"
              class="text-right"
              v-bind="header.cellProps"
            >
              {{ getTFootText(header) }}
            </th>
          </tr>
        </tfoot>
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';
import BaseModal from '@/components/general/BaseModal.vue';

import statusOptions from '@/data/debtStatus.js';
import { useLinkAutocomplete } from '@/composables/autocomplete/link-autocomplete.js';
import usePaginationTable from '@/composables/table/table-pagination.js';

import { getDistributions } from '@/services/api/debt.js';
import { formatMoney } from '@/utils/formatter/number.js';
import { format as formatDate } from '@/utils/formatter/date.js';
import { computed, ref } from 'vue';
import { moneyFormat } from '@/legacy/functions/filters';

const debtTypesOptions = [
  { value: 0, title: 'Debt collection' },
  { value: 1, title: 'Regular collection' },
];

const filterExpand = ref(false);
const printExpand = ref(true);

const search = ref({
  ref_num: null,
  dossier: null,
  status: [],
  rental: null,
  debtor_person_id: null,
  creditor_person_id: null,
  debtor_department_id: null,
  creditor_department_id: null,
  date_min: null,
  date_max: null,
  completed: null,
  sortBy: [],
});

const {
  link: debtorObject,
  attrs: debtorAttrs,
  listeners: debtorListeners,
} = useLinkAutocomplete();

const {
  link: creditorObject,
  attrs: creditorAttrs,
  listeners: creditorListeners,
} = useLinkAutocomplete();

const headers = computed(() => {
  if (printExpand.value) {
    return [
      { title: 'Ref. number', key: 'debt.ref_num', sortable: false },
      { title: 'Docket number', key: 'debt.dossier', sortable: false },
      { title: 'Pricipal(s)', key: 'debt.links.creditor', sortable: false },
      { title: 'Addressee(s)', key: 'debt.links.debtor', sortable: false },
      {
        title: 'Extra costs',
        key: 'extra_cost',
        align: 'end',
        value: (item) => formatMoney(item.extra_cost),
        sum: true,
        cellProps: { class: 'text-red' },
      },
      {
        title: 'For RSI',
        key: 'pro_rata',
        align: 'end',
        value: (item) => formatMoney(item.pro_rata),
        sum: true,
        cellProps: { class: 'text-red' },
      },
      {
        title: 'For Principal(s)',
        key: 'for_client',
        align: 'end',
        value: (item) => formatMoney(item.for_client),
        sum: true,
        cellProps: { class: 'text-blue' },
      },
      {
        title: 'Date',
        key: 'date',
        align: 'end',
        nowrap: true,
        value: (item) => formatDate(item.date, 'shortDate'),
      },
    ];
  }

  return [
    { title: 'Ref. number', key: 'debt.ref_num', sortable: false },
    { title: 'Docket number', key: 'debt.dossier', sortable: false },
    { title: 'Pricipal(s)', key: 'debt.links.creditor', sortable: false },
    { title: 'Addressee(s)', key: 'debt.links.debtor', sortable: false },
    {
      title: 'For Principal(s)',
      key: 'for_client',
      align: 'end',
      value: (item) => formatMoney(item.for_client),
      sum: true,
      cellProps: { class: 'text-blue' },
    },
    {
      title: 'Date',
      key: 'date',
      align: 'end',
      nowrap: true,
      value: (item) => formatDate(item.date, 'shortDate'),
    },
  ];
});

const {
  attrs: tableAttrs,
  listeners: tableListeners,
  fetchItems,
} = usePaginationTable({
  headers: headers,
  sortBy: [{ key: 'date', order: 'desc' }],
  fetchRequest: getDistributions,
  prepareSearch() {
    updateSearch();

    return {
      ...search.value,
    };
  },
});

function updateSearch() {
  search.value.debtor_person_id = null;
  search.value.debtor_department_id = null;
  search.value.creditor_person_id = null;
  search.value.creditor_department_id = null;

  if (debtorObject?.type && debtorObject.id) {
    if (debtorObject?.type == 'person') {
      search.value.debtor_person_id = debtorObject.id;
    } else if (debtorObject?.type == 'department') {
      search.value.debtor_department_id = debtorObject.id;
    }
  }

  if (creditorObject?.type && creditorObject.id) {
    if (creditorObject?.type == 'person') {
      search.value.creditor_person_id = creditorObject.id;
    } else if (creditorObject?.type == 'department') {
      search.value.creditor_department_id = creditorObject.id;
    }
  }
}

function exportExcel() {
  let url = new URL(
    route('debts.lists.distributions.export'),
    window.location.origin
  );

  updateSearch();

  Object.keys(search.value).forEach((key) => {
    if (!['sortBy'].includes(key) && search.value[key]) {
      url.searchParams.append(key, search.value[key]);
    }
  });

  open(url, '_blank');
}

/**
 * @param {object} header
 * @param {string} header.key
 * @returns {string}
 */
function getTFootText(header) {
  if (header.sum) {
    const key = header.key;
    let total = 0;

    tableAttrs.items.forEach((item) => {
      total += Number(item[key] || 0);
    });

    return moneyFormat(total);
  }

  return '';
}
</script>
